module.exports = function () {
    // floats all labels that have value on the page
    function floatAllLabels() {
        $('.form-group input, .form-group .custom-select, .form-group select').each(function () {
            if ($(this).val() || $(this).attr('autofocus')) {
                let $container = $(this).parent().parent();
                let $containerSelect = $(this).parent();
                let $wrappedContainer = $(this).closest('.form-group');
                if ($(this).siblings('.form-control-label').length > 0) {
                    $container = $(this).siblings('.form-control-label');
                }

                let $label = $container.find('.form-control-label');
                if ($label.attr('for') == $(this).attr('id')) {
                    $container.find('.form-control-label').not('.no-animation').css({
                        'top': '-20px',
                        'font-size': '10px',
                        'padding-left': '0'
                    });
                }
                $($wrappedContainer, $containerSelect, $container).find('.form-control-label').not('.no-animation').css({
                    'top': '-20px',
                    'font-size': '10px',
                    'padding-left': '0'
                });
            }
        });
    }

    // registering handler to body so ajax injected forms/modals also work
    $('body').on('focus', '.form-group input, .form-group .custom-select, .form-group select', function () {
        let $formControlLabel;
        let $wrappedContainer = $(this).closest('.form-group');
        let isPasswordResetPage = $('.page[data-action="Account-SetNewPassword"]').length > 0;

        if(isPasswordResetPage) {
            $formControlLabel = $(this).parent().find('.form-control-label');
        } else {
            $formControlLabel = $(this).parent().parent().find('.form-control-label');
        }

        $formControlLabel.not('.no-animation').css({
            'top': '-20px',
            'font-size' : '10px',
            'padding-left' : '0'
        });

        $wrappedContainer.find('.form-control-label').not('.no-animation').css({
            'top': '-20px',
            'font-size' : '10px',
            'padding-left' : '0'
        });
    }).on('blur', function(){
        if ($(this).val() == "") {
            let $formControlLabel;
            let isPasswordResetPage = $('.account-page form.change-password-form').length > 0;
            let $wrappedContainer = $(this).closest('.form-group');

            if(isPasswordResetPage) {
                $formControlLabel = $(this).parent().find('.form-control-label');
            } else {
                $formControlLabel = $(this).parent().parent().find('.form-control-label');
                $formControlLabelSelect = $(this).parent().find('.form-control-label');
            }

            $formControlLabel.not('.no-animation').css({
                'top': '1em',
                'font-size' : '14px',
                'padding-left' : '10px'
            });
            $wrappedContainer.find('.form-control-label').not('.no-animation').css({
                'top': '1em',
                'font-size' : '14px',
                'padding-left' : '10px'
            });
        }
    });

    // when the page loads or there's an ajax completed,
    // and if field is pre-filled make sure the label is moved up
    $(window).on('load', floatAllLabels);
    $(document).ajaxComplete(floatAllLabels);
};
