'use strict';

const Hosted = require('core/components/video/hosted');
const VideoModal = require('core/components/video/videoModal');
const videojs = require('video.js/dist/video.min.js');

var waterfall = {};

function enableHoverZoom() {
    var $zoomView = $('.zoom-view-dialog');

    $zoomView.find('div:not(.primary-images-main) .slide').each((index, slide) => {
        var $slide = $(slide);

        if (!$slide.hasClass('no-zoom')) {
            var zoomImageUrl = $slide.data('zoom-image') || $slide.find('img').attr('src');
            $slide.zoom({
                url: zoomImageUrl,
                magnify: 1.25,
                touch: true,
                on: 'click',
                onZoomIn: function() {
                    $(this).closest('.slide').addClass('zoomed');
                },
                onZoomOut: function() {
                    $(this).closest('.slide').removeClass('zoomed');
                }
            });
        }
    });
}


waterfall.enableWaterfallZoomModal = function() {

    $('.product-detail .product-img.btn, .product-detail .product-img-m.btn, .product-detail a.plus').on('click', function(e) {
        e.preventDefault();

        var $this = $(this);
        var currentIndex = $this.data('index');
    
        if ($('#zoomViewModal').length !== 0) {
            $('#zoomViewModal').remove();
        }
        var htmlString = ''
            + '<div class="modal fade" id="zoomViewModal" role="dialog">'
            + '<span class="enter-message sr-only" ></span>'
            + '<div class="modal-dialog zoom-view-dialog mw-100">'
            + '<!-- Modal content-->'
            + '<div class="modal-content">'
            + '<div class="modal-header">'
            + '    <button type="button" class="close pull-right" data-dismiss="modal">'
            + '        <span aria-hidden="true">&times;</span>'
            + '        <span class="sr-only"> </span>'
            + '    </button>'
            + '</div>'
            + '<div class="modal-body"></div>'
            + '</div>'
            + '</div>'
            + '</div>';
        $('body').append(htmlString);

        var $modal = $('#zoomViewModal');
        $modal.find('.modal-body').spinner().start();

        var $waterfallContainer = $('.waterfall-container');

        $modal.find('.modal-body').empty();

        var modalBodyString = '<!-- Images -->';

        var dImages = $waterfallContainer.find('div.slide > img.product-img');

        var currentIndexImageMarkup = '';
        var otherImagesMarkup = '';

        Array.from(dImages).forEach((dImage, idx) => {
            var isCurrentIndex = currentIndex === $(dImage).data('index');
            var zImageUrl = $(dImage).parent().data('zoom-image');
            var imgMarkup = '<div class="slide col-12 d-block">'
                + '<a class="plus plus-lg text-center"><div class="icon-zoom before"></div></a>'
                + '<img class="col-12 product-img" src="' + zImageUrl + '" alt="image number ' + (idx+1) + '" />'
                + '</div>';

            if (isCurrentIndex) {
                currentIndexImageMarkup += imgMarkup;
            } else {
                otherImagesMarkup += imgMarkup;
            }
        });

        modalBodyString = currentIndexImageMarkup + otherImagesMarkup;


        $modal.find('.modal-body').html(modalBodyString);

        $modal.modal('show');
        $.spinner().stop();
        enableHoverZoom();
    });
    $('.waterfall-see-more').on('click', function(e) {
        e.preventDefault();
        $('.waterfall-extra-images').addClass('d-md-block');
        $('.waterfall-see-more').removeClass('d-md-block');
        $('.waterfall-fade-overlay').removeClass('d-md-block');
    });
}

module.exports = waterfall;
