'use strict';

const abSlider = require('core/components/slider');
const productTile = require('client_core/components/productTile');
const toastHelpers = require('core/components/toast');

function addProduct(data) {
	const uuid = data.uuid || null;
	const pid = data.pid || null;
	const addProductUrl = data.action || null;
	const productName = data.name || null;

	$.spinner().start();

	$.ajax({
		url: addProductUrl,
		method: 'POST',
		dataType: 'json',
		data: {
			pid: pid,
			uuid: uuid,
			name: productName
		},
		success: function(response) {
			const alertType = response.success ? 'success' : 'warning';
			toastHelpers.methods.show(alertType, response.msg, false);
			updateCartData();
		},
		error: function(error) {
			console.error('Save For Later Failed:', error);
			$.spinner().stop();
		}
	});
}

function removeProduct(data, skipMsg) {
	const uuid = data.uuid || null;
	const pid = data.pid || null;
	const removeProductUrl = data.action || null;
	const productName = data.name || null;

	$.ajax({
		url: removeProductUrl,
		method: 'GET',
		dataType: 'json',
		data: {
			pid: pid,
			uuid: uuid,
			name: productName
		},
		success: function(response) {
			if (!skipMsg) {
				const alertType = response.success ? 'success' : 'warning';
			    toastHelpers.methods.show(alertType, response.msg, false);
			}
			updateCartData();
		},
		error: function(error) {
			console.error('Remove From Save For Later Failed:', error);
			$.spinner().stop();
		}
	});
}

function moveToCart(data) {
	const uuid = data.uuid || null;
	const pid = data.pid || null;
	const addToCartUrl = data.action || null;
	const productName = data.name || null;

	$.spinner().start();

	$.ajax({
		url: addToCartUrl,
		method: 'POST',
		dataType: 'json',
		data: {
			pid: pid,
			uuid: uuid,
			name: productName,
			quantity: 1
		},
		success: function(response) {
			const alertType = response.error ? 'warning' : 'success';
			toastHelpers.methods.show(alertType, response.message, false);
			if (response.error === false) {
				$('.empty-cart-content').hide();
				data.action = data.removeaction;
				removeProduct(data, true);
				abSlider.initializeSliders($parsedHtml.body);
				productTile.init($parsedHtml.body);
			}
		},
		error: function(error) {
			console.error('Add To Cart Failed:', error);
			$.spinner().stop();
		}
	});
}

function updateCartData() {
	const $cartContainer = $('#cartProductListContainer');
	const updateUrl = $cartContainer.data('update-url');

	$.spinner().stop();
	$.spinner().start();

	$.ajax({
		url: updateUrl,
		method: 'GET',
		dataType: 'html',
		success: function(response) {
			const $parsedHtml = $(response);
			const cartListHtml = $parsedHtml.find('#cartProductListContainer').html();
			$cartContainer.html(cartListHtml);

			// Update Save For Later
			const saveForLaterListHtml = $parsedHtml.find('#saveForLaterContainer').html();
			const saveForLaterListHtmlTest = $parsedHtml.find('#saveForLaterContainer .slider');
			$('#saveForLaterContainer').html(saveForLaterListHtml);

			// Update classes for page
			const cartPageClasses = $parsedHtml.find('.cart-page').attr('class');
			$('.cart-page').attr('class', cartPageClasses);

			// Update Totals
			const cartRightRailHtml = $parsedHtml.find('.cart-page .order-total-summary').html();
			$('.cart-page .order-total-summary').html(cartRightRailHtml);

            //Update Mobile Totals
            const desktopTotals = $parsedHtml.find('.grand-total-sum').html();
            $('.grand-total-mobile').html(desktopTotals);

			// Update Mini Cart Counter
            const miniCartIconHtml = $parsedHtml.find('.minicart .minicart-quantity').html();
            $('.minicart .minicart-quantity').html(miniCartIconHtml);

            // get the product count from the mini cart markup...
            const cartItemCount = Number($parsedHtml.find('.minicart .minicart-quantity').text());
            // Show empty cart
            if (cartItemCount === 0) {
                // see if we've already set things

                if (!$('.totals-card').hasClass('d-none')) {
                    $('.totals-card').addClass('d-none');
                    $('.promo-code-card').hide();
                    // disable  the checkout btn
                    $('.checkout-btn').addClass('disabled');
                    // hide  the paypal button, and additional payment buttons..
                    $('.cartAdditionalPaymentButtons').addClass('d-none');
                }
            } else {
                // see if we've already set things
                if ($('.totals-card').hasClass('d-none') || $('.totals-card').css('display') === 'none') {
                    $('.totals-card').removeClass('d-none').css('display', '');
                    $('.promo-code-card').show();
                    // enable the checkout btn
                    $('.checkout-continue').show();
                    $('.checkout-btn').removeClass('disabled');
                    // show the paypal button
                    $('.cartAdditionalPaymentButtons').removeClass('d-none');
                }
            }
			// paypal.Reinitialize();
			$.spinner().stop();

            // fire event
            $('body').trigger('cart:saveforlater:update');
			abSlider.initializeSliders($parsedHtml.body);
			productTile.init($parsedHtml.body);
		},
		error: function(error) {
			console.error(error);

			// paypal.Reinitialize();
			$.spinner().stop();
		}
	});
}

module.exports = {
	saveProductForLater: function() {
		$('body').on('click', '.save-for-later-btn', function(e) {
			e.preventDefault();
			addProduct($(this).data());
		});
	},
	removeProductSavedForLater: function() {
		$('body').on('click', '.remove-save-for-later-btn', function(e) {
			e.preventDefault();
			removeProduct($(this).data());
		});
	},
	addToCartSavedForLater: function() {
		$('body').on('click', '.move-to-cart-save-for-later-btn', function(e) {
			e.preventDefault();
			moveToCart($(this).data());
		});
	},
	updateCartData: function() {
		$('body').on('cart:updateData', updateCartData);
	}
};
