var main = require('client_core/main');

/* Client Custom */
main.baseFiles.menu = require('./components/menu');
main.baseFiles.floatingFieldLabels = require('./components/floatingFieldLabels');
main.baseFiles.headerSearch = require('./search/headerSearch');
main.baseFiles.base = require('./product/base');
main.baseFiles.quickView = require('./product/quickView');
main.baseFiles.miniCart = require('./components/miniCart');
main.baseFiles.paymentTabs = require('./components/paymentTabs');
main.baseFiles.waterfall = require('./components/waterfall');
main.baseFiles.slider = require('./components/slider').init;
main.baseFiles.cart = require('./cart');
main.baseFiles.saveForLater = require('./product/saveForLater');
main.baseFiles.wishlist= require('./wishlist/wishlist').init;
main.baseFiles.modal = require('./components/modal').init;
main.baseFiles.hotspot = require('./components/hotspot');
main.baseFiles.unavailableProducts = require('./cart/unavailableProducts');


module.exports = main;
