'use strict';

module.exports = {
	unavailableProductsUpdate: function() {
		$('body').on('unavailableProducts:update', function (e, data) {
			console.log('unavailableProducts:update', data);
			if (data && data.items && data.items.length) {
				data.items.forEach(function (item) {
					if (item.mojoInventoryInfo && item.mojoInventoryInfo.available === false) {
						$('.cart-product-card[data-uuid='+ item.UUID +'] .line-item-attributes.item-unavailable').removeClass('d-none');
					} else {
						$('.cart-product-card[data-uuid='+ item.UUID +'] .line-item-attributes.item-unavailable').addClass('d-none');
					}
				});
			}
		});
	}
};