'use strict';

var wishlistBase = require('client_core/wishlist/wishlist');

/**
 * attaches on click for clicking the "x" from wishlist show or account show
 */
wishlistBase.removeFromWishlist = function() {
    $('body').on('click', '.remove-from-wishlist', function (e) {
        e.preventDefault();
        var url = $(this).data('url');
        var elMyAccount = $('.account-page').length;

        // If user is in my account page, call Wishlist-RemoveProductAccount() end point and replace html with response
        if (elMyAccount > 0) {
            $('.wishlist-account-card').spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'html',
                data: {},
                success: function (html) {
                    $('.wishlist-account-card>.order-card').remove();
                    $('.wishlist-account-card').append(html);
                    $('.wishlist-account-card').spinner().stop();
                },
                error: function () {
                    var $elToAppend = $('.wishlist-account-card');
                    $elToAppend.spinner().stop();
                    var msg = $elToAppend.data('error-msg');
                    wishlistBase.displayMessage($elToAppend, msg);
                }
            });
        // else user is in wishlist landing page, call Wishlist-RemoveProduct() end point and re-render container page
        } else {
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: {},
                success: function (data) {
                    var pageNumber = $('.wishlistItemCardsData').data('page-number') - 1;
                    wishlistBase.renderNewPageOfItems(pageNumber, false);
                    wishlistBase.displayMessage(data);
                    var $wishlistQty = $('.wishlist-qty');
                    if ($wishlistQty.length > 0) {
                        $wishlistQty.text(data.listLength);
                    }
                },
                error: function () {
                    $.spinner().stop();
                    wishlistBase.displayMessage(data);
                }
            });
        }
    });
}

module.exports = wishlistBase;


