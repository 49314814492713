'use strict';

// INOTE: Had to bring this file over to disable the hardcoded
// class check applying max height to modals. 2/13/22

var base = require('client_core/components/modal');

/**
 * Generates the modal window on the first call.
 * adding edit modal to logic rendering full pdp link
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
function getModalHtmlElement(elementID, dialogClasses, selectedValueUrl) {
    var id = elementID || 'customModal';
    var qvPDPLink = (id == 'quickViewModal') || (id == 'editWishlistProductModal') ? '    <a class="full-pdp-link" href=""></a>' : '';
    dialogClasses = dialogClasses || '';

    if ($('#' + id).length !== 0) {
        $('#' + id).remove();
    }
    var htmlString = '<div class="modal fade" id="' + id + '" role="dialog">'
        + '<span class="enter-message sr-only"></span>'
        + '<div class="modal-dialog ' + dialogClasses + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + qvPDPLink
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}


base.getModalHtmlElement = getModalHtmlElement;

module.exports = base;
