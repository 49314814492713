'use strict';
const base = require('client_core/cart/cart');

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.numItems == 1 ? data.resources.numberOfItem : data.resources.numberOfItems);
    $('.shipping-cost span').empty().append(data.totals.totalShippingCost);
    $('.tax-total span').empty().append(data.totals.totalTax);
    $('.grand-total-sum').empty().append(data.totals.grandTotalLessGiftCertificatePaymentInstrumentsFormatted);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });

    // Update cart title with quantity
    $('sup, cart-number-of-items').text(data.pageTitle);

    // Show empty cart
    if (data.numItems === 0) {
        $('.empty-cart-content').show();
        $('.cart-items-header').hide();
        $('.cart-content').hide();
        $('.empty-cart-container').hide();
        $('.order-totals-card').hide();
        $('.promo-code-card').hide();
        $('.checkout-continue').hide();
        $('.totals-card').hide();
    }

    $('.shipping-total-cost span').text(data.totals.totalShippingCost);

    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0 && !data.totals.hasFreeShipping) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total span').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    if (data.payment.giftCertificatePaymentInstruments.length) {
        $('.giftcertificate-discount').removeClass('d-none');
        $('.giftcertificate-discount-label').text(data.totals.giftCertificatePaymentInstrumentsLabel);
        $('.giftcertificate-discount-total').text('- ' + data.totals.giftCertificatePaymentInstrumentsTotalFormatted);
    } else {
        $('.giftcertificate-discount').addClass('d-none');
    }

    if (data.totals.grandTotalLessGiftCertificatePaymentInstrumentsValue) {
        $('.cartAdditionalPaymentButtons').removeClass('d-none');
    } else {
        $('.cartAdditionalPaymentButtons').addClass('d-none');
    }

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }

        if (item.customizer && item.customizer.isCustomized) {
            var $item = $('.cart-product-card[data-uuid="' + item.UUID + '"]');
            item.customizer.addonProducts.forEach(function (addonItem) {
                if (addonItem) {
                    var addonItemHTML = '';
                    var $addonContainer = $('[data-customizer-addon-product-uuid="'+addonItem.uuid+'"] .addon-product-price-container');
                    if (addonItem.hasDiscount) {
                        $addonContainer.addClass('text-nowrap');
                        addonItemHTML = `<span class="addon-product-price strike-through">+${addonItem.price.formatted}</span>
                                         <span class="addon-product-sales-price">+${addonItem.salesPrice.formatted}</span>`;
                    } else {
                        $addonContainer.removeClass('text-nowrap');
                        addonItemHTML = `<span class="addon-product-price">+${addonItem.salesPrice.formatted}</span>`;
                    }
                    $addonContainer.html(addonItemHTML);
                }
            });
            $('.item-total-' + item.UUID + ' .line-item-total-price-amount').text(item.customizer.priceWithCustomizations.adjusted.formatted);

            if (item.customizer.approachingCustomizationDiscounts && item.customizer.approachingCustomizationDiscounts.length) {
                $item.find('.line-item-approaching-discounts').show();
                var approachingDiscountsHtml = '';
                item.customizer.approachingCustomizationDiscounts.forEach(function(discount) {
                    var $tooltip = $('<span class="tooltip-whats-this text-nowrap" data-html="true" data-toggle="tooltip"></span></div>')
                        .text(discount.tooltipLink)
                        .attr('title', '<div class="text-left">' + discount.tooltipBody + '</div>')
                    var tooltipHtml = $('<div>').append($tooltip).html();
                    tooltipHtml = '<div class="text-right ml-2">' + tooltipHtml + '</div>';
                    approachingDiscountsHtml = approachingDiscountsHtml
                        + '<div class="custom-approaching-discount warning-text d-flex flex-column flex-lg-row justify-content-between"><span>'
                        + discount.discountMsg
                        + '</span>'
                        + tooltipHtml
                        + '</div>';
                });
                $item.find('.line-item-approaching-discounts .col-12').html(approachingDiscountsHtml);
                $('body').trigger('tooltip:init');
            } else {
                $item.find('.line-item-approaching-discounts').hide();
            }
        } else {
            $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
            $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
            $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
        }
    });

    $('body').trigger('unavailableProducts:update', data);

    if (data.totals.klarnaTotal) {
        $('klarna-placement').attr('data-purchase-amount',
        data.totals.klarnaTotal);
        window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
        window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' });
    }
}

base.updateCartTotals = updateCartTotals;

/**
 * Copied from workwear_client_core cartridge to skip error message if it's unavailable msg.
 * Updated from base to prevent removing the `.cart` html when the basket is empty
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error && !data.valid.hasUnavailableProduct || !data.mojoInventoryInfo.isOrderable) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.valid-cart-error .close').trigger('click');
        }

        if (data.mojoInventoryInfo.errorMsg) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.mojoInventoryInfo.errorMsg + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.mojo-inventory-alert .close').trigger('click');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
        $('.valid-cart-error .close').trigger('click');
    }
}

base.validateBasket = validateBasket;

module.exports = base;
