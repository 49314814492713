'use strict';

var tinySlider = require('tiny-slider/src/tiny-slider');
require('jquery.scrollintoview');

var SiteConstants = require('constants/SiteConstants');
var mediumBreakpoint = SiteConstants.BreakpointSizes.md;
var largeBreakpoint = SiteConstants.BreakpointSizes.lg;

var abSlider = require('core/components/slider');

// Build out slide html for replacing images on variant selection
abSlider.getUpdatedSlideItems = function(images, isThumbnail) {
    var slideItems = [];

    images['large'].forEach(image => {
        var $slideElement = $('<div></div>').attr('class', 'slide');
        var $imageElement = $('<img/>');

        if (!isThumbnail) {
            $slideElement.attr('data-zoom-image', images['hi-res'].length ? images['hi-res'][image.index].url : images['large'][image.index].url);
        }

        var wtrfModClasses = '';
        if ($('.waterfall-container').length > 0) {
            wtrfModClasses = ' product-img-m btn border-0';
        }

        $imageElement.attr({
            'src': image.url,
            'alt': image.alt + ' image number ' + (parseInt(image.index) + 1).toFixed(0),
            'class': 'd-block img-fluid' + wtrfModClasses,
            'itemprop': 'image'
        });

        $slideElement.append($imageElement);
        slideItems.push($slideElement[0]);
    });

    return slideItems;
}

// Listen for the slider:update event and reinitialize slider with new slides
abSlider.bindSliderUpdateEvent = function($sliderContainer, slider) {
    $sliderContainer.off('slider:update').on('slider:update', '.slider', (event, data) => {
        var $updatedSliderContainer = $(event.target).closest('.slider-container');
        var isThumbnail = $updatedSliderContainer.hasClass('slider-thumbnails');
        var updatedSlideItems = data !== undefined ? abSlider.getUpdatedSlideItems(data.images, isThumbnail) : null;
        var updatedParams = abSlider.getSliderParams($updatedSliderContainer, updatedSlideItems);

        // Update carousel classes to handle number of images changing
        if (isThumbnail) {
            var $thumbnailContainer = $updatedSliderContainer.closest('.primary-images-thumbnails');
            var $mainImageContainer = $thumbnailContainer.prev('.primary-images-main');

            if (data.images.small && data.images.small.length > 1) {
                $thumbnailContainer.removeClass('d-none');
                $mainImageContainer.addClass('product-has-thumbnails col-lg-9');
            } else {
                $thumbnailContainer.addClass('d-none');
                $mainImageContainer.removeClass('product-has-thumbnails col-lg-9');
            }
        }

        // Remove and re-add slider markup if there are new slides to show
        var wtrfModClasses = '';
        if ($('.waterfall-container').length > 0) {
            wtrfModClasses = ' text-center';
        }

        if (updatedParams.slideItems) {
            var $newSliderElement = $('<div></div>').attr({
                'class': 'slider' + wtrfModClasses,
                'id': updatedParams.container.id
            });
            var $newSliderControls = $updatedSliderContainer.find('.slider-thumbnail-controls').addClass('d-none');
            $newSliderElement.html(updatedParams.slideItems);
            $updatedSliderContainer.html($newSliderElement);
            updatedParams.container = $newSliderElement[0];

            // If this is a thumbnails slider, also add the arrow controls and update the item count
            if (isThumbnail) {
                $updatedSliderContainer.append($newSliderControls);
                updatedParams.items = updatedParams.slideItems.length;
                updatedParams.responsive[mediumBreakpoint].items = updatedParams.slideItems.length;
                updatedParams.responsive[largeBreakpoint].items = updatedParams.slideItems.length;
            }
        }

        // Reinitialize slider using new params
        slider = tinySlider.tns(updatedParams);
        slider.events.on('transitionEnd', abSlider.slideIntoView);
        abSlider.enableImageZoom($updatedSliderContainer);

        if (isThumbnail) {
            abSlider.handleThumbnailArrows($updatedSliderContainer, slider);
        }
    });
}

module.exports = abSlider;
