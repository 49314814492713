module.exports = function () {
    //need to add class to payment option tabs for redkap without affecting the other sites
    $('.payment-options-block .payment-options .nav-link').each(function(){
        $(this).addClass('custom-control-label');
    });
    $('.credit-card-tab').append(
        '<span class="tab-label">Credit Card</span>'
    );
    $('.paypal-tab').append(
        '<span class="tab-label">PayPal</span>'
    );
    $('.klarna-tab').append(
        '<span class="tab-label">Klarna</span>'
    );
};
