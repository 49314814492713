
module.exports = function () {
    $('.search-field').on('input', function (){
        $(this).parent().find('.btn-primary').removeAttr('disabled');
    }).on('blur', function(){
        if ($(this).val() == "") {
            $(this).parent().find('.btn-primary').attr('disabled', 'disabled');
        }
    });
};
